import axios from '@/common/axios'
import qs from 'qs'

export function addInternalAuditReport (data) {
  return axios({
    method: 'post',
    url: '/quality/auditReport/add',
    data: qs.stringify(data)
  })
}

export function deleteInternalAuditReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/auditReport/delete/' + id
  })
}

export function updateInternalAuditReport (data) {
  return axios({
    method: 'put',
    url: '/quality/auditReport/update',
    data: qs.stringify(data)
  })
}

export function selectInternalAuditReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/auditReport/info/' + id
  })
}

export function selectInternalAuditReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/auditReport/list',
    params: query
  })
}
