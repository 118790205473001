<template>
  <div id="internalAuditReport">
    <el-dialog
      :title="internalAuditReportFormTitle"
      width="680px"
      :visible.sync="internalAuditReportDialogVisible"
      :close-on-click-modal="false"
      @close="internalAuditReportDialogClose"
    >
      <el-form
        ref="internalAuditReportFormRef"
        :model="internalAuditReportForm"
        :rules="internalAuditReportFormRules"
        label-position="right"
        label-width="125px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核目的" prop="purpose">
              <el-input v-model="internalAuditReportForm.purpose" placeholder="请输入审核目的" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核范围" prop="auditScope">
              <el-input v-model="internalAuditReportForm.auditScope" placeholder="请输入审核范围" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核依据" prop="auditBasis">
              <el-input v-model="internalAuditReportForm.auditBasis" placeholder="请输入审核依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="受审部门" prop="dept">
              <el-input v-model="internalAuditReportForm.dept" placeholder="请输入受审部门" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核组长" prop="auditTeamLeader">
              <el-input v-model="internalAuditReportForm.auditTeamLeader" placeholder="请输入审核组长" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="内审员" prop="internalAuditor">
              <el-input v-model="internalAuditReportForm.internalAuditor" placeholder="请输入内审员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="auditDate">
              <el-date-picker v-model="internalAuditReportForm.auditDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核过程综述" prop="auditProcess">
              <el-input
                v-model="internalAuditReportForm.auditProcess"
                placeholder="请输入审核过程综述"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="不符合项统计分析" prop="nonAnalysis">
              <el-input
                v-model="internalAuditReportForm.nonAnalysis"
                placeholder="请输入不符合项统计分析"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核结论" prop="auditConclusion">
              <el-input
                v-model="internalAuditReportForm.auditConclusion"
                placeholder="请输入审核结论"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="纠正及预防措施" prop="measure">
              <el-input
                v-model="internalAuditReportForm.measure"
                placeholder="请输入纠正及预防措施"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编辑人" prop="editor">
              <el-input v-model="internalAuditReportForm.editor" placeholder="请输入编辑人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编辑日期" prop="editDate">
              <el-date-picker v-model="internalAuditReportForm.editDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="internalAuditReportForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="internalAuditReportForm.reviewDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="internalAuditReportForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approveDate">
              <el-date-picker v-model="internalAuditReportForm.approveDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="internalAuditReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="internalAuditReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="评审主持人">
        <el-input v-model="searchForm.reviewHost" placeholder="请输入评审主持人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="internalAuditReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="purpose" label="审核目的" />
      <el-table-column prop="auditScope" label="审核范围" />
      <el-table-column prop="auditBasis" label="审核依据" />
      <el-table-column prop="dept" label="受审部门" />
      <el-table-column prop="auditTeamLeader" label="审核组长" />
      <el-table-column prop="internalAuditor" label="内审员" />
      <el-table-column prop="auditDate" label="审核日期" />
      <el-table-column prop="auditProcess" label="审核过程综述" />
      <el-table-column prop="nonAnalysis" label="不符合项统计分析" />
      <el-table-column prop="auditConclusion" label="审核结论" />
      <el-table-column prop="measure" label="纠正及预防措施" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column prop="editDate" label="编制时间" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核时间" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column prop="approveDate" label="批准时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="internalAuditReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInternalAuditReport, deleteInternalAuditReport, updateInternalAuditReport, selectInternalAuditReportInfo, selectInternalAuditReportList } from '@/api/quality/internalAuditReport'

export default {
  data () {
    return {
      internalAuditReportDialogVisible: false,
      internalAuditReportFormTitle: '',
      internalAuditReportForm: {
        id: '',
        purpose: '',
        auditScope: '',
        auditBasis: '',
        dept: '',
        auditTeamLeader: '',
        internalAuditor: '',
        auditDate: '',
        auditProcess: '',
        nonAnalysis: '',
        auditConclusion: '',
        measure: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveDate: ''
      },
      internalAuditReportFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      internalAuditReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectInternalAuditReportList(this.searchForm).then(res => {
      this.internalAuditReportPage = res
    })
  },
  methods: {
    internalAuditReportDialogClose () {
      this.$refs.internalAuditReportFormRef.resetFields()
      this.internalAuditReportForm.id = ''
    },
    internalAuditReportFormSubmit () {
      if (this.internalAuditReportFormTitle === '内部审核报告详情') {
        this.internalAuditReportDialogVisible = false
        return
      }
      this.$refs.internalAuditReportFormRef.validate(async valid => {
        if (valid) {
          if (this.internalAuditReportFormTitle === '新增内部审核报告') {
            await addInternalAuditReport(this.internalAuditReportForm)
          } else if (this.internalAuditReportFormTitle === '修改内部审核报告') {
            await updateInternalAuditReport(this.internalAuditReportForm)
          }
          this.internalAuditReportPage = await selectInternalAuditReportList(this.searchForm)
          this.internalAuditReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.internalAuditReportFormTitle = '新增内部审核报告'
      this.internalAuditReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInternalAuditReport(row.id)
        if (this.internalAuditReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.internalAuditReportPage = await selectInternalAuditReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.internalAuditReportFormTitle = '修改内部审核报告'
      this.internalAuditReportDialogVisible = true
      this.selectInternalAuditReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.internalAuditReportFormTitle = '内部审核报告详情'
      this.internalAuditReportDialogVisible = true
      this.selectInternalAuditReportInfoById(row.id)
    },
    selectInternalAuditReportInfoById (id) {
      selectInternalAuditReportInfo(id).then(res => {
        this.internalAuditReportForm.id = res.id
        this.internalAuditReportForm.purpose = res.purpose
        this.internalAuditReportForm.auditScope = res.auditScope
        this.internalAuditReportForm.auditBasis = res.auditBasis
        this.internalAuditReportForm.dept = res.dept
        this.internalAuditReportForm.auditTeamLeader = res.auditTeamLeader
        this.internalAuditReportForm.internalAuditor = res.internalAuditor
        this.internalAuditReportForm.auditDate = res.auditDate
        this.internalAuditReportForm.auditProcess = res.auditProcess
        this.internalAuditReportForm.nonAnalysis = res.nonAnalysis
        this.internalAuditReportForm.auditConclusion = res.auditConclusion
         this.internalAuditReportForm.measure = res.measure
        this.internalAuditReportForm.editor = res.editor
        this.internalAuditReportForm.editDate = res.editDate
        this.internalAuditReportForm.reviewer = res.reviewer
        this.internalAuditReportForm.reviewDate = res.reviewDate
        this.internalAuditReportForm.approver = res.approver
        this.internalAuditReportForm.approveDate = res.approveDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInternalAuditReportList(this.searchForm).then(res => {
        this.internalAuditReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInternalAuditReportList(this.searchForm).then(res => {
        this.internalAuditReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInternalAuditReportList(this.searchForm).then(res => {
        this.internalAuditReportPage = res
      })
    },
    
  }
}
</script>

<style>
#internalAuditReport .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
